define('ember-moment/computeds/to-now', ['exports', 'ember', 'ember-new-computed', 'moment', 'ember-moment/utils/is-descriptor'], function (exports, Ember, emberComputed, moment, isDescriptor) {

  'use strict';



  exports['default'] = computedAgo;
  var _get = Ember['default'].get;

  function computedAgo(date, maybeInputFormat, maybeHidePrefix) {
    var args = [date];

    var computed = emberComputed['default'](date, {
      get: function get() {
        var momentArgs = [_get(this, date)];

        if (arguments.length > 1) {
          var desc = isDescriptor['default'].call(this, maybeInputFormat);
          var input = desc ? _get(this, maybeInputFormat) : maybeInputFormat;

          if (desc && computed._dependentKeys.indexOf(maybeInputFormat) === -1) {
            computed.property(maybeInputFormat);
          }

          momentArgs.push(input);
        }

        return moment['default'].apply(this, momentArgs).toNow(maybeHidePrefix);
      }
    });

    return computed.property.apply(computed, args).readOnly();
  }

});