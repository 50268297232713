define('ember-paper/components/paper-autocomplete-item', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tagName: 'li',
    attributeBindings: ['tabindex', 'role'],
    classNameBindings: ['isSelected:selected'],
    tabindex: 0,
    role: 'option',

    label: Ember['default'].computed('item', function () {
      return this.lookupLabelOfItem(this.get('item'));
    }),

    isSelected: Ember['default'].computed('selectedIndex', function () {
      return this.get('selectedIndex') === this.get('index');
    }),

    lookupLabelOfItem: function lookupLabelOfItem(model) {
      return this.get('lookupKey') ? Ember['default'].get(model, this.get('lookupKey')) : model;
    },

    click: function click() {
      this.sendAction('pick', this.get('item'));
    }
  });

});