define('ember-paper/components/paper-backdrop', ['exports', 'ember', 'ember-css-transitions/mixins/transition-mixin'], function (exports, Ember, TransitionMixin) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(TransitionMixin['default'], {
    tagName: 'md-backdrop',
    classNames: ['md-default-theme'],
    classNameBindings: ['opaque:md-opaque', 'isLockedOpen:md-locked-open'],

    // TransitionMixin:
    transitionClass: 'ng',
    shouldTransition: Ember['default'].computed.bool('opaque'),
    addDestroyedElementClone: function addDestroyedElementClone(parent, index, clone) {
      parent.append(clone);
    },

    // Hammer event handler for tapping backdrop
    tapHammer: null,

    didInsertElement: function didInsertElement() {
      var hammer = new Hammer(this.get('element'));
      hammer.on('tap', Ember['default'].run.bind(this, this.onTap));
      this.set('tapHammer', hammer);
    },

    onTap: function onTap(e) {
      e.preventDefault();
      this.sendAction('tap');
    }

  });

});