define('ember-paper/components/paper-select', ['exports', 'ember', 'ember-paper/components/paper-input'], function (exports, Ember, PaperInput) {

  'use strict';

  exports['default'] = PaperInput['default'].extend({
    label: Ember['default'].computed.alias('placeholder'),
    value: Ember['default'].computed.alias('model'),
    itemLabelCallback: Ember['default'].computed.alias('item-label-callback'),
    onOpen: Ember['default'].computed.alias('on-open')
  });

});