define('ember-paper/mixins/hasblock-mixin', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Mixin.create({
    hasBlock: Ember['default'].computed(function () {
      if (typeof this._super.hasBlock === 'undefined') {
        return !!this.get('template');
      }
      return this.hasBlock;
    })
  });

});