define('ember-paper/components/paper-icon', ['exports', 'ember', 'ember-paper/mixins/color-mixin'], function (exports, Ember, ColorMixin) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend(ColorMixin['default'], {
    tagName: 'md-icon',
    classNames: ['paper-icon', 'md-font', 'material-icons', 'md-default-theme'],
    classNameBindings: ['iconClass', 'sizeClass', 'spinClass'],

    spin: false,
    reverseSpin: false,

    iconClass: Ember['default'].computed('icon', function () {
      return Ember['default'].String.dasherize(this.get('icon'));
    }),

    spinClass: Ember['default'].computed('spin', 'reverseSpin', function () {
      if (this.get('spin')) {
        return ' md-spin';
      } else if (this.get('reverseSpin')) {
        return ' md-spin-reverse';
      }
    }),

    sizeClass: Ember['default'].computed('size', function () {
      switch (this.get('size')) {
        case 'lg':
          return ' md-lg';
        case 'sm':
          return ' md-sm';
        case 2:
          return ' md-2x';
        case 3:
          return ' md-3x';
        case 4:
          return ' md-4x';
        case 5:
          return ' md-5x';
      }
    })

  });
  /*click() {
    if (this.get('action')) {
      this.sendAction('action', this.get('param'));
    }
  }*/

});