define('ember-paper/mixins/events-mixin', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Mixin.create({
    touchStart: function touchStart(e) {
      return this.down(e);
    },
    mouseDown: function mouseDown(e) {
      this.down(e);
    },
    touchEnd: function touchEnd(e) {
      return this.up(e);
    },
    mouseUp: function mouseUp(e) {
      return this.up(e);
    },
    touchCancel: function touchCancel(e) {
      return this.up(e);
    },
    mouseLeave: function mouseLeave(e) {
      return this.up(e);
    },
    up: Ember['default'].K,
    down: Ember['default'].K,
    contextMenu: Ember['default'].K,

    /*
     * Move events
     */

    mouseMove: function mouseMove(e) {
      return this.move(e);
    },

    touchMove: function touchMove(e) {
      return this.move(e);
    },

    pointerMove: function pointerMove(e) {
      return this.move(e);
    },

    move: Ember['default'].K
  });

});