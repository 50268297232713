define('ember-paper/mixins/proxy-mixin', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Mixin.create({
    proxiedComponents: Ember['default'].computed(function () {
      return Ember['default'].A();
    }),
    register: function register(component) {
      if (!component.get('skipProxy')) {
        this.get('proxiedComponents').addObject(component);
      }
    },
    unregister: function unregister(component) {
      this.get('proxiedComponents').removeObject(component);
    },
    isProxiedComponent: function isProxiedComponent(component) {
      return this.get('proxiedComponents').contains(component);
    }
  });

});