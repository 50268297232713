define('ember-paper/components/paper-nav-container', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tagName: 'section',
    attributeBindings: ['layoutAttr:layout', 'flex'],
    layoutAttr: 'row',
    sideBar: null

  });

});