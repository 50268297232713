define('ember-storage/services/storage', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var storage = {
    'local': window.localStorage,
    'session': window.sessionStorage
  };

  exports['default'] = Ember['default'].Service.extend({
    prefix: 'es',
    type: 'local',

    _prefix: function _prefix(key) {
      return this.get('prefix') + '__' + key;
    },
    setUpEventListener: Ember['default'].on('init', function () {
      var self = this,
          regexp = new RegExp('^(' + this.get('prefix') + '__)');
      this._notify = function (evnt) {
        self.notifyPropertyChange(evnt.key.replace(regexp, ''));
      };
      if (!window.addEventListener) {
        window.attachEvent('storage', this._notify, false);
      } else {
        window.addEventListener('storage', this._notify, false);
      }
    }),
    willDestroy: function willDestroy() {
      this._super();
      if (!window.removeEventListener) {
        window.detachEvent('storage', this._notify, false);
      } else {
        window.removeEventListener('storage', this._notify, false);
      }
    },
    unknownProperty: function unknownProperty(k) {
      var key = this._prefix(k),
          type = this.get('type');
      // if we don't use JSON.parse here then observing a boolean doesn't work
      return storage[type][key] && JSON.parse(storage[type][key]);
    },
    setUnknownProperty: function setUnknownProperty(k, value) {
      var key = this._prefix(k),
          type = this.get('type');

      if (Ember['default'].isNone(value)) {
        delete storage[type][key];
      } else {
        storage[type][key] = JSON.stringify(value);
      }
      this.notifyPropertyChange(k);
      return value;
    },
    clear: function clear(keyPrefix) {
      this.beginPropertyChanges();
      var prefix = keyPrefix || this.get('prefix'),
          regexp = new RegExp('^(' + prefix + '__)'),
          type = this.get('type'),
          toDelete = [];

      for (var i = 0; i < storage[type].length; i++) {
        var key = storage[type].key(i);
        // don't nuke *everything* in localStorage... just keys that match our pattern
        if (key.match(regexp)) {
          toDelete.push(key);
        }
      }
      toDelete.forEach(function (key) {
        delete storage[type][key];
        key = key.replace(regexp, '');
        this.set(key);
      }, this);
      this.endPropertyChanges();
    }
  });

});