define('ember-paper/components/paper-menu-item', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tagName: 'md-menu-item',

    actions: {
      action: function action() {
        this.sendAction('action', this.get('param'));
      }
    }

  });

});