define('ember-i18n/utils/locale', ['exports', 'ember'], function (exports, Ember) {

  'use strict';


  exports['default'] = Locale;

  function Locale(id, container) {

    // On Construction:
    //  1. look for translations in the locale (e.g. pt-BR) and all parent
    //     locales (e.g. pt), flatten any nested keys, and then merge them.
    //  2. walk through the configs from most specific to least specific
    //     and use the first value for `rtl` and `pluralForm`
    //  3. Default `rtl` to `false`
    //  4. Ensure `pluralForm` is defined
    this.id = id;
    this.container = container;
  }

  Locale.prototype.rebuild = function () {
    this.translations = getFlattenedTranslations(this.id, this.container);
    this._setConfig();
  };

  Locale.prototype._setConfig = function () {
    var _this = this;

    walkConfigs(this.id, this.container, function (config) {
      if (_this.rtl === undefined) {
        _this.rtl = config.rtl;
      }
      if (_this.pluralForm === undefined) {
        _this.pluralForm = config.pluralForm;
      }
    });

    var defaultConfig = this.container.lookupFactory('ember-i18n@config:zh');

    if (this.rtl === undefined) {
      Ember['default'].warn('ember-i18n: No RTL configuration found for ' + this.id + '.');
      this.rtl = defaultConfig.rtl;
    }

    if (this.pluralForm === undefined) {
      Ember['default'].warn('ember-i18n: No pluralForm configuration found for ' + this.id + '.');
      this.pluralForm = defaultConfig.pluralForm;
    }
  };

  Locale.prototype.getCompiledTemplate = function (fallbackChain, count) {
    var translation = this.findTranslation(fallbackChain, count);
    var result = translation.result;

    if (Ember['default'].typeOf(result) === 'string') {
      result = this._compileTemplate(translation.key, result);
    }

    if (result == null) {
      result = this._defineMissingTranslationTemplate(fallbackChain[0]);
    }

    Ember['default'].assert('Template for ' + translation.key + ' in ' + this.id + ' is not a function', Ember['default'].typeOf(result) === 'function');

    return result;
  };

  Locale.prototype.findTranslation = function (fallbackChain, count) {
    if (this.translations === undefined) {
      this.rebuild();
    }

    var result = undefined;
    var i = undefined;
    for (i = 0; i < fallbackChain.length; i++) {
      var key = fallbackChain[i];
      if (count != null) {
        var inflection = this.pluralForm(count);
        result = this.translations[key + '.' + inflection];
      }

      if (result == null) {
        result = this.translations[key];
      }

      if (result) {
        break;
      }
    }

    return {
      key: fallbackChain[i],
      result: result
    };
  };

  Locale.prototype._defineMissingTranslationTemplate = function (key) {
    var missingMessage = this.container.lookupFactory('util:i18n/missing-message');
    var locale = this.id;

    function missingTranslation(data) {
      return missingMessage(locale, key, data);
    }

    missingTranslation._isMissing = true;
    this.translations[key] = missingTranslation;
    return missingTranslation;
  };

  Locale.prototype._compileTemplate = function (key, string) {
    var compile = this.container.lookupFactory('util:i18n/compile-template');
    var template = compile(string, this.rtl);
    this.translations[key] = template;
    return template;
  };

  function getFlattenedTranslations(id, container) {
    var result = {};

    var parentId = parentLocale(id);
    if (parentId) {
      Ember['default'].merge(result, getFlattenedTranslations(parentId, container));
    }

    var translations = container.lookupFactory('locale:' + id + '/translations') || {};
    Ember['default'].merge(result, withFlattenedKeys(translations));

    return result;
  }

  // Walk up confiugration objects from most specific to least.
  function walkConfigs(id, container, fn) {
    var appConfig = container.lookupFactory('locale:' + id + '/config');
    if (appConfig) {
      fn(appConfig);
    }

    var addonConfig = container.lookupFactory('ember-i18n@config:' + id);
    if (addonConfig) {
      fn(addonConfig);
    }

    var parentId = parentLocale(id);
    if (parentId) {
      walkConfigs(parentId, container, fn);
    }
  }

  function parentLocale(id) {
    var lastDash = id.lastIndexOf('-');
    return lastDash > 0 ? id.substr(0, lastDash) : null;
  }

  function withFlattenedKeys(object) {
    var result = {};

    Object.keys(object).forEach(function (key) {
      var value = object[key];

      if (Ember['default'].typeOf(value) === 'object') {
        value = withFlattenedKeys(value);

        Object.keys(value).forEach(function (suffix) {
          result[key + '.' + suffix] = value[suffix];
        });
      } else {
        result[key] = value;
      }
    });

    return result;
  }

});