define('ember-paper/mixins/proxiable-mixin', ['exports', 'ember', 'ember-paper/mixins/proxy-mixin'], function (exports, Ember, ProxyMixin) {

  'use strict';

  exports['default'] = Ember['default'].Mixin.create({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var proxy = this.nearestOfType(ProxyMixin['default']);
      if (proxy) {
        proxy.register(this);
      }
    },

    processProxy: null
  });

});